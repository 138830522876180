import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditAhorro, addAhorroNota, delAhorro, getAhorroById, getAhorros, deleteAhorroNota} from '../../services';
import {IAhorro, IAhorroFilter, IAhorroRes, IAhorroState, INotaAhorro} from "../types/IAhorro";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";


const initialState: IAhorroState = {
    status: "idle",
}

export const mostrarAhorros = createAsyncThunk(
    'mostrarAhorros', async (params:IAhorroFilter|undefined, thunkAPI ) => {
        const {ahorros} = thunkAPI.getState() as AppState;
        let paramsFilter = params || ahorros.filtered;

        const response = await getAhorros(paramsFilter)
        return {data: response.data as IAhorroRes, params: response.config.params}
    }
)
export const mostrarAhorroById = createAsyncThunk(
    'mostrarAhorroById', async (id:number) => {
        const response = await getAhorroById(id);
        return {data: response.data as IAhorro}
    }
)
export const agregarEditarAhorro = createAsyncThunk(
    'Ahorros/agregarEditar', async (ahorro: IAhorro) => {
        const response = await addEditAhorro(ahorro);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return {data: response.data, edit:!!ahorro.id};
    }
);

export const eliminarAhorro = createAsyncThunk(
    'ahorro/eliminar', async (id: number) => {
        const response = await delAhorro(id);
        if (response.status === 200 || response.status === 204) historyHelper.replace("ahorros");
        return id;
    }
);



export const agregarAhorroNota = createAsyncThunk(
    'Ahorro/agregarNota', async (params:INotaAhorro) => {
        const response = await addAhorroNota(params);
        if (response.status === 200 || response.status === 204) {
            historyHelper.back()
        };

        return {data: response.data};
    }
);

export const eliminarAhorroNota = createAsyncThunk(
    'Ahorro/eliminarNota', async (params:{ahorroId:number, id:number}) => {
        await deleteAhorroNota(params.ahorroId,params.id);
        return params.id;
    }
);




const slice = createSlice({
    name: 'mostrarAhorros',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarAhorros
        builder.addCase(mostrarAhorros.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAhorros.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarAhorros.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarAhorroById
        builder.addCase(mostrarAhorroById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarAhorroById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarAhorroById.rejected, (state ) => {
            state.status = "idle";
        })
        //--agregarEditarAhorro
        builder.addCase(agregarEditarAhorro.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarAhorro.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
                state.list.items.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalCount
                }

            }
        })
        builder.addCase(agregarEditarAhorro.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarAhorro
        builder.addCase(eliminarAhorro.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAhorro.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarAhorro.rejected, (state ) => {
            state.status = "idle";
        })



        //--agregar Ahorro Nota
        builder.addCase(agregarAhorroNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarAhorroNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload;
            const notas = [...(state.byId?.notas ?? [])];
            notas.push(data);

            state.byId = {...state.byId, notas}
        })
        builder.addCase(agregarAhorroNota.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminar Ahorro Nota
        builder.addCase(eliminarAhorroNota.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarAhorroNota.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            const notas = [...(state.byId?.notas?.filter(n=>n.id !== id) ?? [])];

            state.byId = {...state.byId, notas}

        })
        builder.addCase(eliminarAhorroNota.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;

import history from "../helpers/history";
import {store} from '../redux/store';
import Notifications from "react-notification-system-redux";
import {errorToNotifObj, getLSLang} from "../helpers";

import {getIdToken} from './auth'; //isLoggedIn
import _ from 'lodash';
import axios from "axios";
import {ICompania} from "../redux/types/ICompania";
import {IEditUser, IRegisterUser, IUserAgente} from "../redux/types/IUser";
import {ICliente, IClienteFilter} from "../redux/types/ICliente";
import {IFilter} from "../redux/types/Icommon";
import {IPago, IPagoFilter, IPagoPost} from "../redux/types/IPago";
import {INota, IPrestamo, IPrestamoFilter} from "../redux/types/IPrestamo";
import {ISolicitud, ISolicitudFilter} from "../redux/types/ISolicitud";
import {IRuta, IRutaOrdenar} from "../redux/types/IRuta";
import {ICartera} from "../redux/types/ICartera";
import {IPlantilla} from "../redux/types/IPlantilla";
import {ICaja, ICajaCierreFilter} from "../redux/types/ICaja";
import {IGasto, IGastoFilter} from "../redux/types/IGasto";
import {IUsoMultiple} from "../redux/types/IUsosMultiples";
import {IRol} from "../redux/types/IRoles";
import {IFeriado} from "../redux/types/IFeriado";
import {IAppUser, IAppUserNota} from "../redux/types/IAppUser";
import {userLogout} from "../redux/reducers/authReducer";
import {IIngreso, IIngresoFilter} from "../redux/types/IIngreso";
import {IAhorro, IAhorroFilter, INotaAhorro} from "../redux/types/IAhorro";

let base_url = "https://api3.prestamistapp.net";//"https://api.prestamistapp.net/";
if(window.location.hostname.indexOf("test") !== -1 || window.location.hostname.indexOf("loclhost") !== -1 ){
    base_url = "https://api3.prestamistapp.net";//"https://apitest.prestamistapp.net/";
}
export {base_url};
export const API = axios.create({
    baseURL: base_url,
    // timeout: 1000,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "es-US" }
})
API.interceptors.request.use(function (request) {
    const tokenId = getIdToken();
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    request.headers["x-lang"] = getLSLang("l")
    // const decoded = jwt_decode(localStorage._token);
    //
    // const currentTime = Date.now() / 1000;
    // if(decoded.exp < currentTime) {
    //     store.dispatch(logoutUser());
    //     window.location.href = '/login'
    // }
    //
    if (tokenId) request.headers.Authorization = `Bearer ${tokenId}`;

   // if (!window.navigator.onLine) alert("no estas conectado a internet");
    return request;
})
API.interceptors.response.use(function (response) {
    //notificaciones en redux
    let reduxNot = ["getsolicitudes","marcarleida", "comunesenviarcorreo","enviarcorreo"];
    // let url = response.config.url?.replaceAll("/","").toLowerCase();

    // if(!!url && !reduxNot.includes(url)){
    let url = response.config.url?.toLowerCase();

    if (!reduxNot.some(i=> url?.includes(i))) {

        let msj = "";
        switch (response.config.method) {
            case "post":
                msj = "Registro guardado";
                break
            case "put":
                msj = "Registro actualizado";
                break
            case "delete":
                msj = "Registro eliminado";
                break
            default:
                msj = "";
        }
        if (msj)
            store.dispatch(Notifications.success({title: msj + ' con exito.', autoDismiss: 4}));
    }
    return response;
}, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
           // localStorage.removeItem('_token');
            if (history.location.pathname.indexOf("/report") !== -1) {
                window.close();
            }
          //  history.replace("/login", { backurl: history.location.pathname })
            store.dispatch(userLogout())
        } else if (error.response.status === 403) {
            store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: 'Necesita permisos para realizar esta acción.', autoDismiss: 5 }));
        } else {
            store.dispatch(Notifications.error(errorToNotifObj(error?.response?.data)));
        }
    }
    return Promise.reject(error);
});
export const API_PUBLIC = axios.create({
    baseURL: base_url,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "en-US" }
})
API_PUBLIC.interceptors.request.use(function (request) {
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    request.headers["x-lang"] = getLSLang("l")
    return request;
})

export const API_PORTAL_CLIENTE = axios.create({
    baseURL: base_url,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "en-US" }
})
API_PORTAL_CLIENTE.interceptors.request.use(function (request) {
    if(!request.url?.toLowerCase().includes("login")) {
        let tokenId = localStorage.getItem("cliToken");
        if(tokenId) request.headers.Authorization = "Bearer "+tokenId;
    }
    return request;
})
//Compania
export function getCompanias() {
    return API.get('Companias');
}
export function editCompanias(obj:ICompania) {
    let params = _.omitBy(obj, _.isNil);
    return API.put('Companias', params);
}
export function getCompaniasByUser() {
    return API.get('/Publicos/CompaniaPorUsuario');
}
export function generarBackUp() {
    //   return API.get(`reportes/GenerarBackUp`)
    return axios({
        url: base_url + 'reportes/GenerarBackUp',
        method: 'GET',
        responseType: 'blob',
        headers: { 'Accept': 'application/vnd.ms-excel', 'Authorization': 'Bearer ' + getIdToken(), }
    })
}


//perfil
export function getPerfil() {
    return API.get('Auth/Profile');
}
export function ediPerfil(obj:IEditUser) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Auth/Profile', params);
}
export function postSuscripcion(obj:any) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('/Auth/Suscripcion', params);
}
export function getCuentasXcobrar(obj:{rutaId?:number ,ciclosPago?:number,fechaDesde:string,fechaHasta:string}) {
    let params = _.omitBy(obj, (v) => !v);
    return API.get('/Reportes/CalendarioGeneral', { params })
}

//DashBoard
export function getCxC(incluirCxcAldia?:boolean,rutaId?:number) {
    return API.get('/Reportes/CuentasxCobrar', { params: {incluirCxcAldia, rutaId} })
}
export function getWidgets(rutaId?:number) {
    return API.get('/Reportes/Dashboard',{ params: rutaId? { rutaId }:{} });
}
export function getWidgetsGasIng(ciclo?:number,rutaId?:number) {
    return API.get('/Reportes/ResumenIngresos', { params: { ciclo,rutaId } });
}
export function getResumenGrafico(year:number = new Date().getFullYear(), rutaId?:number) {
    return API.get(`/Reportes/ResumenGrafico`, { params: { year,rutaId } });
}
export function getEgresosGrafico(year:number = new Date().getFullYear(), rutaId?:number) {
    return API.get(`/Reportes/EgresosDetalle`, { params: { year,rutaId } });
}
export function getFlujoCajasGrafico(year:number = new Date().getFullYear(), rutaId?:number) {
    return API.get(`/Reportes/FlujoCajasGrafico`, { params: { year,rutaId } });
}
export function getSaldoAntiguedad(rutaId?:number) {
    let params = _.omitBy({rutaId}, (v) => _.isNil(v));

    return API.get(`/Reportes/SaldoAntiguedad`, {params} );
}
export function getIngresoVsEgreso(obj:{fechaDesde?:string, fechaHasta?:string, agenteId?:number, rutaId?:number, carteraId?:number}) {
    let params = _.omitBy(obj, (v) => !v);

    return API.get(`/Reportes/IngresoVsEgreso`, {params} );
}

//Clientes
export function getClientes(filter?:IClienteFilter|number, simpleObj?:boolean) {
    if (typeof filter === 'number') {
        return API.get(`Clientes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get(simpleObj ? "Clientes/ListadoSimple" : "Clientes", { params });
    }
}
export function addEditCliente(obj:ICliente) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Clientes/'+obj.id, params): API.post('Clientes', params);
}
export function delCliente(id:number) {
    return API.delete(`Clientes/${id}`);
}

export function rateCliente(params:{ id: number, rate: number }) {
    return API.post(`/clientes/${params.id}/calificar`, params);
}
export function cedulaExiste(cedula:string) {
    return API.get(`/clientes/validarPorCedula?cedula=${cedula}`);
}
export function importClientes(obj:ICliente[]) {
    return API.post('Clientes/Importar', {clientes:obj});
}
//portal cliente
export function clienteLogin({user,userName,password}:{ user?: string, userName: string, password: string }) {

    return axios.post(base_url+'/PortalClientes/Login', {userName,password}, {
        headers: {contentType:'application/json; charset=utf-8', "x-agente": user}
    });
}
export function clientePrestamos(params?:IFilter) {
    return API_PORTAL_CLIENTE.get("/PortalClientes/Prestamos", {params});
}
export function clientePagoById(id:number) {
    return API_PORTAL_CLIENTE.get(`/PortalClientes/Pagos/${id}`);
}
export function clientePrestamosById(id:number) {
    return API_PORTAL_CLIENTE.get(`/PortalClientes/Prestamos/${id}`);
}
export function clienteAgregarPago(obj:IPago) {

    let params = {
        prestamoId: obj.prestamoId,
        monto:      obj.capital,
        comentario: obj.comentario,
        adjunto:    obj.adjunto
    }

    return API_PORTAL_CLIENTE.post(`/PortalClientes/Pagos`,params);
}

//Agente
export function getAgentes(filter?:{name?:string}) {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Agentes", { params });
}
export function getAgenteById(id:number) {
    return API.get(`Agentes/${id}`)
}
export function addEditAgente(obj:IUserAgente) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Agentes/'+obj.id, params): API.post('Agentes', params);
}

export function editPWAgente(params:{ id: number, clave: string }) {
    return API.post(`Agentes/${params.id}/ChangePassword`, params);
}
export function delAgente(id:number) {
    return API.delete(`Agentes/${id}`);
}
export function lockToggleAgente(id:number) {
    return API.post(`Agentes/${id}/Lock`);
}
//Prestamo
export function getPrestamo(id?:number) {

    return id ? API.get(`prestamos/${id}`) : API.get(`prestamos`);
}
export function getPrestamos(filter?:IPrestamoFilter|number) {
    if (typeof filter == 'number') {
        return API.get(`Prestamos/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("prestamos", { params });
    }
}
export function calCuotasPrestamo(id:number, cuotas:number) {
    return API.get(`Prestamos/${id}/MontoCuota`, { params: { cuotas,id } })
}
export function addPrestamo({prestamoIdReenganche, ...obj}:IPrestamo) {
    let params = _.omitBy(obj, (v) => !v);

    if(prestamoIdReenganche) return API.post(`Prestamos/${prestamoIdReenganche}/Reenganche`, params);

    return API.post('Prestamos', params);
}
export function editPrestamo(obj:IPrestamo) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Prestamos/'+obj.id, params);
}
export function addPrestamoNota(obj:INota) {
    let params = _.omitBy(obj, (v) => !v);

    return API.post(`/Prestamos/${params.prestamoId}/Notas`, params);
}
export function deletePrestamoNota(prestamoId:number,id:number) {
    return API.delete(`/Prestamos/${prestamoId}/Notas/${id}` );
}
export function cancelarPrestamo(id:number, nota = "", anularPagos = false) {
    return API.delete(`Prestamos/${id}`, { params: { nota, anularPagos } });
}
export function ajustarCapitalPrestamo(obj:{id: number, fecha?:string,cajaId?: number, monto: number, comentario?: string, tipoFormaPago?: number}) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post(`Prestamos/${params.id}/AjustarCapital`, params);
}
export function recalcularMoraPrestamo(obj:{id: number, porcientoMora?:number,fechaUltimaMora?: string}) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post(`Prestamos/${params.id}/RecalcularMora`, params);
}
// export function getContrato(id:number, plantillaId?:number) {
//     return API.get(`Prestamos/${id}/contrato`, { params: { id, plantillaId } });
// }
export function setIncobrablePrestamo(id:number) {
    return API.post(`/Prestamos/${id}/MarcarIncobrable`);
}
//pagos
export function getPagos(filter?:IPagoFilter) {
    // if(preId)
    let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
    return API.get('Pagos/', { params });
}
export function getPagoById(id:number) {
    return API.get('Pagos/' + id);
}
export function getFechaHoraActual() {
    return API.get('Comunes/FechaHoraActual');
}
export function postPago(obj:IPagoPost) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post("Pagos", params);
}
export function postPagoInicial(obj:IPagoPost){
    let params = _.omitBy(obj, (v) => !v);
    return API.post("Pagos/PagoInicial", params);
}
export function postPagoRapido(obj:IPagoPost) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post("Pagos/PagoRapido", params);
}
export function deletePago(id:number) {
    // if(preId)
    return API.delete(`Pagos/${id}` );
}
export function getBancos() {
    return API.get("bancos")
}
export function postEditBanco(name:string, bankId?:number) {
    if (bankId) {
        return API.put(`bancos/${bankId}`, { nombre: name, banId: bankId })
    } else {
        return API.post("bancos", { nombre: name })
    }
}
export function deleteBanco(id:number) {
    return API.delete(`bancos/${id}`)
}
//Solicitudes
export function getSolicitudes(filter:ISolicitudFilter | number) {
    if (typeof filter == 'number') {
        return API.get(`Solicitudes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Solicitudes", { params });
    }
}
export function addSolicitud(obj:ISolicitud) {
    return API.post('Solicitudes', obj);
}
export function rechazarSolicitud(id:number) {
    return API.post(`Solicitudes/${id}/Rechazar`);
}
export function aprobarSolicitud(obj:ISolicitud) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post(`Solicitudes/${params.id}/Aprobar`, params);
}
export function editSolicitud(obj:ISolicitud) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Solicitudes/'+params.id, params);
}
export function delSolicitud(id:number) {
    return API.delete(`Solicitudes/${id}`);
}

//Rutas
export function getRutas(filter?:any) {
    return API.get('Rutas', { params: { ...filter } });
}
export function addEditRuta(obj:IRuta) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Rutas/'+obj.id, params): API.post('Rutas', params);
}
export function delRuta(id:number) {
    return API.delete(`Rutas/${id}`);
}
export function inactivateRuta(id:number) {
    return API.post(`Rutas/${id}/Inactivar`);
}
export function ordenarClienteRuta(params:IRutaOrdenar) {
    return API.post(`Rutas/${params.id}/Ordenar`, params);
}
//Feriados
export function getFeriados(name?:string) {
    return API.get('Feriados', { params: { name } });
}
export function addEditFeriado(obj:IFeriado) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Feriados/'+obj.id, params): API.post('Feriados', params);
}
export function delFeriado(id:number) {
    return API.delete(`Feriados/${id}`);
}

//Carteras
export function getCarteras(filter?:string|number) {
    if (typeof filter == 'number') {
        return API.get(`Carteras/${filter}`)
    } else {
        return API.get("Carteras", { params: { name:filter } });
    }
}
export function addEditCartera(obj:ICartera) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Carteras/'+obj.id, params): API.post('Carteras', params);
}

export function delCartera(id:number) {
    return API.delete(`Carteras/${id}`);
}
//plantillas
export function getPlantillas(filter?: {name?:string, tipo?:number, mostrarRegistroSistema?:boolean} | number) {
    if (typeof filter == 'number') {
        return API.get(`Plantillas/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => !v);
        return API.get("Plantillas", { params });
    }
}
export function addEditPlantilla(obj:IPlantilla) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Plantillas/'+obj.id, params): API.post('Plantillas', params);
}
export function delPlantilla(id:number) {
    return API.delete(`Plantillas/${id}`);
}
export function getPlantillaConverted(id:number, prestamoId:number) {
    return API.get(`Plantillas/${id}/mostrar`, { params:{prestamoId,id} });
}
//Cajas
export function getCajas(filter?:any|number) {
    if (typeof filter === 'number') {
        return API.get(`Cajas/${filter}`)
    } else {
        return API.get('Cajas', { params: { ...filter } });
    }
}
export function addEditCaja(obj:ICaja) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Cajas/'+params.id, params): API.post('Cajas', params);
}
export function delCaja(id:number) {
    return API.delete(`Cajas/${id}`);
}
export function delCajaCierre(cajaId:number, cierreId: number) {
    return API.delete(`Cajas/${cajaId}/EliminarCierre/${cierreId}`);
}
export function inactivateCaja(id:number) {
    return API.post(`Cajas/${id}/Inactivar`);
}
export function closeCaja(params:{ cajaId: number, balance: number, nota?: string }) {
    return API.post('cajas/'+params.cajaId+'/Cierres', params);
}
export function transferBetweenCajas(params:{ cajaId: number, cajaIdDestino: number, monto?: number }) {
    return API.post(`cajas/${params.cajaId}/Transferencia`, params);
}
export function getCajasMovimientos(id: number, obj:ICajaCierreFilter) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get(`cajas/${id}/Movimientos`, { params });
}
export function getCajasCierres(id: number, params:ICajaCierreFilter) {
    return API.get(`Cajas/${id}/Cierres`, { params });
}
//gastos
export function getGastos(obj:IGastoFilter) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Gastos', { params });
}
export function getGastoById(id:number) {
    return API.get('Gastos/'+id);
}
export function addEditGasto(obj:IGasto) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Gastos', params): API.post('Gastos', params);
}
export function delGasto(id:number) {
    return API.delete(`Gastos/${id}`);
}

//Ahorro
export function getAhorros(obj:IAhorroFilter) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Ahorros', { params });
}
export function getAhorroById(id:number) {
    return API.get('Ahorros/'+id);
}
export function addEditAhorro(obj:IAhorro) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Ahorros', params): API.post('Ahorros', params);
}
export function delAhorro(id:number, nota = "", anularPagos = false) {
    return API.delete(`Ahorros/${id}`, { params: { nota, anularPagos } });
}



export function addAhorroNota(obj:INotaAhorro) {
    let params = _.omitBy(obj, (v) => !v);

    return API.post(`/Ahorros/${params.ahorroId}/Notas`, params);
}
export function deleteAhorroNota(ahorroId:number,id:number) {
    return API.delete(`/Ahorros/${ahorroId}/Notas/${id}` );
}


//AhorrosTransacciones
export function getAhorrosTransacciones(obj:IAhorroFilter) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('AhorrosTransacciones', { params });
}
export function getAhorroTransaccionById(id:number) {
    return API.get('AhorrosTransacciones/'+id);
}
export function addEditAhorroTransaccion(obj:IAhorro) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('AhorrosTransacciones', params): API.post('AhorrosTransacciones', params);
}
export function delAhorroTransaccion(id:number) {
    return API.delete(`AhorrosTransacciones/${id}`);
}

//ingreso
export function getIngresos(obj:IIngresoFilter) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Ingresos', { params });
}
export function getIngresoById(id:number) {
    return API.get('Ingresos/'+id);
}
export function addEditIngreso(obj:IIngreso) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Ingresos', params): API.post('Ingresos', params);
}
export function delIngreso(id:number) {
    return API.delete(`Ingresos/${id}`);
}




/*-------------Otros Mantenimientos--------------------*/
//getUsosMultiples

export function getUsosMultiples(params?:{name?:string, tipo?:number}) {
    return API.get('UsosMultiples', { params });
}
export function addEditUsosMultiple(obj:IUsoMultiple) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('UsosMultiples/'+params.tipo, {id:params.id, descripcion:params.descripcion}): API.post('UsosMultiples', params);
}

export function delUsosMultiple(id:number) {
    return API.delete('UsosMultiples/'+id);
}




//Autorizaciones
export function getAutorizaciones(soloActivas = false) {
    return API.get('Autorizaciones', { params: {soloActivas} });
}
export function addAutorizaciones() {
    return API.post('Autorizaciones');
}
export function delAutorizaciones(id:number) {
    return API.delete('Autorizaciones/'+id);
}

//Roles
export function getRoles(id?:number) {

    if (typeof id == 'number') {
        return API.get(`Roles/${id}`);
    }

    return API.get('Roles');

}
export function getRolById(id:number) {
    return API.get(`Roles/${id}`);
}
export function addEditRol(obj:IRol) {
    let params = _.omitBy(obj, (v) => !v);
    return obj.id ? API.put('Roles/'+obj.id, params): API.post('Roles', params);
}

export function delRol(id:number) {
    return API.delete(`Roles/${id}`);
}
//Estados
export function getEstados() {
    return API.get('Estados');
}
//permisos
export function getPermisos() {
    return API.get('RolesPermisos');
}
//Paises
export function getPaises() {
    return API.get('Paises');
}
//getMonedas
export function getMonedas() {
    return API.get('Monedas');
}
//zona horaria
export function getZonasHorarias() {
    return API.get('ZonasHorarias');
}
//comunes
export function getEnums() {
    return API.get('comunes/Enums');
}
export function getIndicadoresMenu() {
    return API.get('comunes/indicadoresMenu');
}
export function consultarPadron(dni:string) {
    return API.get('/Comunes/ConsultarPadron?dni='+dni);
}
export function getInfoByCedula(cedula:string) {
    return API.get('/Solicitudes/GetInfo?cedula='+cedula);
}
export function getHistorialCrediticio(cedula:string) {
    return API.get('/Reportes/HistorialCrediticio?Dni='+cedula);
}
export function enviarCorreo(email:string, message:string, attachment:string) {
    return API.post('/Comunes/EnviarCorreo', {
        email, message, attachment
    });
}

export function calcularTasa(params: {montoCuota:number, plazo:number, capital:number, tipoAmortizacion:number}) {
    return API.get('Comunes/CalcularTasa',{params});
}


//FeedBack
export function postFeedBack(FeedBack:string) {
    return API.post('Comunes/EvaluarApp',{FeedBack});
}
//notificaciones
export function getNotificaciones(verLeidas:boolean) {
    return API.get('Notificaciones',{params:{verLeidas}});
}
export function readNotificacion(id:number) {
    return API.post('Notificaciones/'+id+'/MarcarLeida');
}
export function delNotificacion(id:number) {
    return API.delete('Notificaciones/'+id);
}
//amort

export function getAmortizacion({monto, tipoAmortizacion, ciclosPago,tasa, cuotas,montoCuota, fechaPrimerPago}:{monto:number, tasa:number, tipoAmortizacion:number, cuotas:number,montoCuota?:number,ciclosPago?:number, fechaPrimerPago:string}) {
    return API.get('comunes/Amortizacion', {params:{monto, tipoAmortizacion, ciclosPago,tasa, cuotas,montoCuota, fechaPrimerPago}} );
}
//admin
export function getAppUsers(obj:any) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Backoffice/Companias', { params });
}

export function getAppUsersById(id:number) {
    return API.get('Backoffice/Companias/' + id);
}
export function addAppUser(obj:IRegisterUser) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post('Backoffice/Companias', { params });
}
export function putAppUser(obj:IAppUser) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Backoffice/Companias/'+params.id, params);
}
export function getAdminEnums() {
    return API.get('Backoffice/Enums');
}
export function addAppUserPago(obj:any) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post('Backoffice/Companias/Pagos', params );
}
export function delAppUserPago(obj:{id:number, companiaId:number}) {
    return API.delete('Backoffice/Companias/Pagos/'+obj.id, {params:obj});
}


export function addAppUserNota(obj:IAppUserNota) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.post('Backoffice/Companias/Notas', params );
}
export function delAppUserNota(id:number, companyId:number) {
    return API.delete('Backoffice/Companias/Notas/'+id+"?companiaid="+companyId);
}
export function sentAppUserNotification(obj: { "companiaId": number, "titulo": string, "mensaje": string }) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));

    return API.post('Backoffice/Notificacion', params );
}

export function getAppUserCuadres(obj:any) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Backoffice/Cuadres', { params });
}
export function addAppUserCuadre(monto: number) {
    return API.post('Backoffice/Cuadres', {monto} );
}
export function getAppUserCuadreById(id:number) {
    return API.get('Backoffice/Cuadres/'+id);
}
export function getAppUserCuadreDetalle() {
    // let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));

    return API.get('Backoffice/Cuadres/GetInfo');
}
export function delAppUserCuadre(id:number) {
    return API.delete('Backoffice/Cuadres/'+id);
}

export function getAppUserBancos(obj:any) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Backoffice/Bancos', { params });
}
export function calAmortizacion(obj:any) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Comunes/Amortizacion', { params });
}

//Get Doc Report
export function exportPrestamoDetalle({id, encabezado, amortizacion, foto, total, pagos}:{id:number, encabezado?:boolean, amortizacion?:boolean, foto?:boolean, total?:boolean, pagos?:boolean}) {

    return axios({
        url: base_url + `/Prestamos/${id}/DetalleExportar`,
        params:{id, encabezado, amortizacion, foto, total, pagos},
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + getIdToken(),"x-lang": getLSLang("l")}
    })
    // 'Accept': 'application/vnd.ms-excel'
}
export function exportAmortizacion({encabezado,total, data}:{encabezado?:boolean, total?:boolean,data?:Object}) {

    return axios({
        url: base_url + `/Comunes/AmortizacionExport`,
        params:{encabezado,total, ...data},
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + getIdToken(),"x-lang": getLSLang("l")}
    })
    // 'Accept': 'application/vnd.ms-excel'
}
export function getPlantillaConvetidad(prestamoId:number, plantillaId?:number) {
    return API.get(`/Prestamos/${prestamoId}/Contrato?plantillaId=${plantillaId}`)
    // 'Accept': 'application/vnd.ms-excel'
}
export function getPlantillaConvetidadHtml(prestamoId:number, plantillaId?:number) {
    return API.get(`/Prestamos/${prestamoId}/ContratoHtml?plantillaId=${plantillaId}`)
    // 'Accept': 'application/vnd.ms-excel'
}
export function getContrato(prestamoId:number, plantillaId?:number) {
    return axios({
        url: base_url + `/Prestamos/${prestamoId}/ContratoExportar?plantillaId=${plantillaId}`,
        // params:{id, plantillaId},
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + getIdToken(), }
    })
    // 'Accept': 'application/vnd.ms-excel'
}
//auth
export function PostRegister(obj:IRegisterUser, validar?:boolean) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('/Auth/Register', {...params, validar: !!validar});
}
export function deleteAccount(motivo?:string) {
    return API.post('/Auth/Unsuscription', {motivo});
}
export function changePassword(params:{ claveActual: string, clave: string }) {
    return API.post('/Auth/ChangePassword', params);
}
export function forgotPassword(email:string) {
    return API.post('Auth/ForgotPassword', {email});
}
export function forgotPasswordConfirm(code: string, clave: string) {
    return API.post('Auth/ForgotPasswordConfirm', {code,clave});
}

//piblic
export function getPublicUser(user:string) {
    return API.get('Publicos/InfoFormularioSolicitud/' + user);
}
export function postSolicitud(user:string, obj:ISolicitud) {
    let params = _.omitBy(obj, (v) => !v);

    return axios.post(base_url+'/Solicitudes', params, {
        headers: {contentType:'application/json; charset=utf-8', "x-agente": user}
    });
}
export function getGeo() {
    // return axios.get("https://api.country.is");
    return axios.get("https://ipapi.co/json");
}

//not used
// export function savePushToken(params) {
//     let obj = _.omitBy(params, (v) => !v);
//     return API.post('/Comunes/SesionToken', obj);
// }
// export function delUsosMultiples(usoGrupo, usoCodigo) {
//     return API.delete('UsosMultiples', { params: { usoGrupo, usoCodigo } });
// }
// export function addUsosMultiples(obj:IUsoMultiple) {
//     let params = _.omitBy(obj, (v) => !v);
//     return obj.id ? API.put('UsosMultiples', params): API.post('UsosMultiples', params);
// }
// export function editUsosMultiples(obj) {
//     let params = _.omitBy(obj, (v) => !v);
//     return obj.id ? API.put('UsosMultiples', params): API.post('UsosMultiples', params);
// }

// export function addGasto(obj) {
//     let params = _.omitBy(obj, (v) => !v);
//     return API.post('Gastos', params);
// }


// export function addCaja(obj) {
//     return API.post('Cajas', obj);
// }
// export function editCaja(obj) {
//     return API.put('Cajas', obj);
// }
// export function addPlantilla(obj) {
//     return API.post('Plantillas', obj);
// }
// export function editPlantilla(obj) {
//     return API.put('Plantillas', obj);
// }
// export function addCartera(obj) {
//     return API.post('Carteras', obj);
// }
// export function editCartera(obj) {
//     return API.put('Carteras/'+obj.id, obj);
// }
//
// export function getGenerales() {
//     return API.get('Generales');
// }
// export function editGenerales(obj) {
//     let params = _.omitBy(obj, _.isNil);
//     return API.put('Generales', params);
// }
// export function postGenerales(obj) {
//     let params = _.omitBy(obj, _.isNil);
//     return API.post('Generales', params);
// }
// export function addCliente(obj) {
//     let params = _.omitBy(obj, (v) => !v);
//     return API.post('Clientes', params);
// }
// export function editCliente(obj) {
//     let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
//     return API.put('Clientes', params);
// }
// export function deleteCliente(id:number) {
//     return API.delete(`Clientes/${id}`)
// }
// export function addAgente(obj) {
//     let params = _.omitBy(obj, (v) => !v);
//     return API.post('Agentes', params);
// }
// export function editAgente(obj) {
//     let params = _.omitBy(obj, (v) => !v);
//     return API.put('Agentes', params);
// }
